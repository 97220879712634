import React, { useState } from 'react';
import {
  Row,
  Container,
  Collapse,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Button,
} from 'reactstrap';
import moment from 'moment';
import styles from '../NewsPostsSection/NewsPostsSection.module.css';

const AnnouncementDocTable = ({ announcements = [], pageSize = 8 }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [collapseOpen, setCollapseOpen] = useState(true);
  const pageCount = Math.ceil(announcements.length / pageSize);

  const handlePreviousClick = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePageClick = (e, page) => {
    e.preventDefault();
    setCurrentPage(page);
  };

  const handleToggle = () => {
    setCollapseOpen(!collapseOpen);
  };

  return (
    <div>
      <h3 className={styles.newsCategory}>
        <div id="announcementsTitle" className={styles.newsCategoryTitle}>
          Documents &nbsp;&nbsp;&nbsp;
          <Button
            color="link"
            title={collapseOpen ? 'Click to collapse' : 'Click to expand'}
            onClick={handleToggle}
          >
            {collapseOpen ? (
              <i className="fa fa-caret-down fa-3x" />
            ) : (
              <i className="fa fa-caret-right fa-3x" />
            )}
          </Button>
        </div>
      </h3>
      <Collapse isOpen={collapseOpen}>
        <Container>
          <Table striped responsive>
            <thead>
              <tr>
                <th>Date</th>
                <th>ID</th>
                <th>Document</th>
                <th>Language</th>
              </tr>
            </thead>
            <tbody>
              {announcements.length > 0 &&
                announcements
                  .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
                  .map(document => (
                    <tr key={`${document.id}-${document.language}`}>
                      <td>{moment(document.date).format('MMM D, YYYY')}</td>
                      <td>{document.document_id}</td>
                      <td>
                        <a
                          href={`https://cms.veym.net/assets/${document.document_file}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {document.title}
                        </a>
                      </td>
                      <td style={{ textTransform: 'capitalize' }}>
                        {document.language}
                      </td>
                    </tr>
                  ))}
            </tbody>
          </Table>
          {pageCount > 1 && (
            <Row className="justify-content-center">
              <Pagination>
                <PaginationItem disabled={currentPage <= 0}>
                  <PaginationLink onClick={handlePreviousClick} previous />
                </PaginationItem>
                {Array.from(Array(pageCount).keys()).map((page, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <PaginationItem active={i === currentPage} key={i}>
                    <PaginationLink onClick={e => handlePageClick(e, i)}>
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}
                <PaginationItem disabled={currentPage >= pageCount - 1}>
                  <PaginationLink onClick={handleNextClick} next />
                </PaginationItem>
              </Pagination>
            </Row>
          )}
        </Container>
      </Collapse>
    </div>
  );
};

export default AnnouncementDocTable;
