import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { Container, Breadcrumb, BreadcrumbItem, Badge } from 'reactstrap';
import Helmet from 'react-helmet';
import axios from 'axios';
import Layout from '../../components/Layout';
import PageHeading from '../../components/PageHeading/PageHeading';
import styles from '../../css/news.module.css';
import NewsPostsSection from '../../components/NewsPostsSection/NewsPostsSection';
import TitleBar from '../../components/TitleBar/TitleBar';
import FacebookPostsSection from '../../components/FacebookPostsSection/FacebookPostsSection';
import AnnouncementDocTable from '../../components/AnnouncementDocTable/AnnouncementDocTable';
import YouTube from 'react-youtube';
import moment from 'moment';
import { useQuery } from 'react-query';

const NewsPage = () => {
  const pageData = useStaticQuery(graphql`
    query NewsPageQuery {
      allWpPost(sort: { fields: date, order: DESC }) {
        edges {
          node {
            title
            id
            slug
            status
            excerpt
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 800) {
                      src
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                date(formatString: "MMMM DD, YYYY")
              }
            }
            uri
            tags {
              nodes {
                slug
                name
              }
            }
            categories {
              nodes {
                slug
                name
              }
            }
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  `);

  const { isLoading: fbLoading, error: fbError, data: fbData } = useQuery(
    'newsFacebookPosts',
    async () => {
      const res = await axios.get('https://prd.veym.net/webapi/facebook.php');
      return res.data.data.slice(0, 24);
    },
    { refetchOnWindowFocus: false }
  );

  const { isLoading: docsLoading, error: docsError, data: docsData } = useQuery(
    'newsAnnouncementDocs',
    async () => {
      const res = await axios.get(
        // 'https://cors-veym.herokuapp.com/https://prd.veym.net/webapi/doclist.php'
        'https://cors-veym.herokuapp.com/https://cms.veym.net/items/official_documents?sort=-date'
      );
      return res.data.data;
    },
    { refetchOnWindowFocus: false }
  );

  const {
    isLoading: newsVideosLoading,
    error: newsVideosError,
    data: newsVideosData,
  } = useQuery(
    'newsPageVideos',
    async () => {
      const res = await axios.get(
        'https://www.googleapis.com/youtube/v3/playlistItems?part=id%2C%20contentDetails&playlistId=PLieCJC88WbxFi4vUl83Fujhr-WejNthSo&key=AIzaSyA97Oqlc3JqnAFB9pV4PT-roR9O4q8iXII&maxResults=50'
      );
      const { data } = res;
      const videoList = data.items
        .map(item => ({
          videoId: item.contentDetails.videoId,
          videoPublishedAt: item.contentDetails.videoPublishedAt,
        }))
        .sort((video1, video2) => {
          return moment(video1.videoPublishedAt).isAfter(
            moment(video2.videoPublishedAt) ? -1 : 1
          );
        });

      return videoList;
    },
    { refetchOnWindowFocus: false }
  );

  // https://www.googleapis.com/youtube/v3/playlistItems?part=id%2C%20contentDetails&playlistId=PLieCJC88WbxFi4vUl83Fujhr-WejNthSo&key=[YOUR_API_KEY]

  const {
    isLoading: tuyenUyInfoIsLoading,
    error: tuyenUyInfoError,
    data: tuyenUyInfoData,
  } = useQuery(
    'getTuyenUyPosts',
    async () => {
      const { data } = await axios.get(
        'https://cors-veym.herokuapp.com/https://cms.veym.net/items/tuyen_uy_communication?sort=-date'
      );

      return data.data;
    },
    { refetchOnWindowFocus: false }
  );

  const { edges: posts } = pageData.allWpPost;

  const facebookSectionLoading = !fbError ? (
    <div>
      <TitleBar>VEYM Facebook Feed</TitleBar>
      <Container>
        <div>
          <br />
          Loading Facebook Posts..
          <div className={styles.spinner}>
            <div className={styles.rect1} />
            <div className={styles.rect2} />
            <div className={styles.rect3} />
            <div className={styles.rect4} />
            <div className={styles.rect5} />
          </div>
        </div>
      </Container>
    </div>
  ) : null;

  const announcementsSectionLoading = !docsError ? (
    <div>
      <TitleBar>Official Announcements</TitleBar>
      <Container>
        <div>
          <br />
          Loading Announcements..
          <div className={styles.spinner}>
            <div className={styles.rect1} />
            <div className={styles.rect2} />
            <div className={styles.rect3} />
            <div className={styles.rect4} />
            <div className={styles.rect5} />
          </div>
        </div>
      </Container>
    </div>
  ) : null;

  const newsVideosSectionLoading = !newsVideosError ? (
    <div>
      <TitleBar>VEYM News</TitleBar>
      <Container>
        <div>
          <br />
          Loading News Broadcasts..
          <div className={styles.spinner}>
            <div className={styles.rect1} />
            <div className={styles.rect2} />
            <div className={styles.rect3} />
            <div className={styles.rect4} />
            <div className={styles.rect5} />
          </div>
        </div>
      </Container>
    </div>
  ) : null;

  const announcementPosts =
    posts &&
    posts
      .filter(
        ({ node: post }) => post.categories.nodes[0].slug === 'announcements'
      )
      .map(({ node: post }) => post);

  const biweeklyPostsTrimmed =
    posts &&
    posts
      .filter(({ node: post }) => post.categories.nodes[0].slug === 'biweekly')
      .slice(0, 24)
      .map(({ node: post }) => post);

  return (
    <Layout>
      <Helmet
        title="VEYM | News"
        meta={[
          {
            name: 'description',
            content:
              "VEYM News - Find all of VEYM's latest updates and announcements!",
          },
        ]}
      />
      <PageHeading>News</PageHeading>
      <Container>
        <Breadcrumb>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          <BreadcrumbItem active>News</BreadcrumbItem>
        </Breadcrumb>
      </Container>
      <br />
      <br />
      <div className={styles.news}>
        {!newsVideosLoading ? (
          <div>
            <TitleBar>VEYM News</TitleBar>
            <br />
            <Container>
              <YouTube
                videoId={newsVideosData && newsVideosData[0].videoId}
                // opts={{ width: '100%' }}
              />
              <br />
              <Link to="/news/broadcasts">View past broadcasts</Link>
            </Container>
          </div>
        ) : (
          newsVideosSectionLoading
        )}
        <br />

        <NewsPostsSection
          posts={announcementPosts && announcementPosts.slice(0, 16)}
          categoryTitle="Announcements"
          pageSizeDesktop={2}
          pageSizeMobile={2}
          desktopView="list"
        />
        <Container>
          <p>
            <Link to="/news/announcements">View All Announcements</Link>
          </p>
        </Container>
        <br />

        {!docsLoading ? (
          <AnnouncementDocTable announcements={docsData} />
        ) : (
          announcementsSectionLoading
        )}
        <br />

        <NewsPostsSection
          posts={biweeklyPostsTrimmed}
          categoryTitle="TNTT Bi-Weekly"
          pageSizeDesktop={3}
          pageSizeMobile={3}
          desktopView="card"
        />

        <Container>
          <p>
            Older TNTT Weekly Newsletters can be found here:{' '}
            <a
              href="https://www.smore.com/u/bantruyenthongveym"
              target="_blank"
              rel="noopener noreferrer"
            >
              TNTT Weekly
            </a>
          </p>
        </Container>
        <br />

        <br />
        <TitleBar>Tuyên Úy Communication</TitleBar>
        <br />
        <Container>
          Messages from Cha Tổng Tuyên Úy to Các Cha Tuyên Úy
          <br />
          <br />
          {tuyenUyInfoIsLoading && (
            <div>
              Loading Tuyen Uy Posts..
              <div className={styles.spinner}>
                <div className={styles.rect1} />
                <div className={styles.rect2} />
                <div className={styles.rect3} />
                <div className={styles.rect4} />
                <div className={styles.rect5} />
              </div>
            </div>
          )}
          {tuyenUyInfoData?.slice(0, 3)?.map(post => (
            <div key={post.id} className={`${styles.tuyenUyPost} my-2`}>
              <Badge>{moment(post.date).format('MMMM D, YYYY')}</Badge>{' '}
              {post.title}
            </div>
          ))}
          {!tuyenUyInfoIsLoading &&
            !tuyenUyInfoError &&
            tuyenUyInfoData.length === 0 && (
              <div>
                <span role="img" aria-label="bubble">
                  💬
                </span>{' '}
                No posts found.{' '}
                <span role="img" aria-label="bubble">
                  💬
                </span>
              </div>
            )}
          <br />
          <div>
            Visit the{' '}
            <Link to="/news/tuyen-uy-communication">
              Tuyên Úy Communication
            </Link>{' '}
            page to see the full posts.
          </div>
        </Container>

        <br />
        <br />

        {!fbLoading && !fbError ? (
          <FacebookPostsSection
            posts={fbData || []}
            categoryTitle="VEYM Facebook Feed"
            pageSize={6}
          />
        ) : (
          facebookSectionLoading
        )}

        {fbError && (
          <div>
            <TitleBar>VEYM Facebook Feed</TitleBar>
            <br />
            <Container>
              <h6>Facebook posts could not be loaded.</h6>
            </Container>
          </div>
        )}

        <Container>
          <p>
            View on Facebook:{' '}
            <a
              href="https://www.facebook.com/pttnttvn.hk"
              target="_blank"
              rel="noopener noreferrer"
            >
              VEYM Facebook <i className="fa fa-facebook-square" />
            </a>
          </p>
        </Container>

        <br />

        <TitleBar>Hướng Tâm Lên</TitleBar>
        <br />
        <Container>
          <p>
            The Hướng Tâm Lên Magazine can be found here:{' '}
            <a
              href="https://issuu.com/pttnttvnhk"
              target="_blank"
              rel="noopener noreferrer"
            >
              Hướng Tâm Lên
            </a>
          </p>
        </Container>
      </div>
      <br />
      <br />
    </Layout>
  );
};

export default NewsPage;

export const frontmatter = {
  title: 'News',
  category: 'pages',
  path: '/news',
  description: 'TNTT News, Announcements',
  tags:
    'news, bi-weekly, biweekly, announcements, facebook, feed, huong tam len, magazine, ban truyen thong, tntt weekly, smore, post, newsfeed, newsletter  ',
};
