import React, { Component } from 'react';
import {
  Row,
  Col,
  Container,
  Collapse,
  Button,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';

import styles from './FacebookPostsSection.module.css';

import FacebookPost from '../FacebookPost/FacebookPost';

class FacebookPostsSection extends Component {
  constructor(props) {
    super(props);

    this.pagesCount = Math.ceil(props.posts.length / props.pageSize);

    this.state = {
      isOpen: true,
      currentPage: 0,
    };
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleClick = (e, index) => {
    e.preventDefault();
    this.setState({ currentPage: index });
  };

  render() {
    const { posts = [], categoryTitle, pageSize } = this.props;
    const { currentPage } = this.state;

    return (
      <div className={styles.fbpostssection}>
        <h3 className={styles.fbCategory}>
          <div
            id={categoryTitle.replace(/\s+/g, '')}
            className={styles.fbCategoryTitle}
          >
            {categoryTitle}
            &nbsp;&nbsp;&nbsp;
            <Button
              color="link"
              title={
                this.state.isOpen ? 'Click to collapse' : 'Click to expand'
              }
              onClick={this.toggle}
            >
              {this.state.isOpen ? (
                <i className="fa fa-caret-down fa-3x" />
              ) : (
                <i className="fa fa-caret-right fa-3x" />
              )}
            </Button>
          </div>
        </h3>
        <Collapse isOpen={this.state.isOpen}>
          <Container>
            <Row className="justify-content-center">
              {posts
                .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
                .map(post => (
                  <Col
                    key={post.id}
                    lg={4}
                    md={6}
                    sm={12}
                    className="tntt-weekly"
                    align="center"
                    style={{ padding: '.25em .25em' }}
                  >
                    <FacebookPost post={post} />
                  </Col>
                ))}
            </Row>
          </Container>
          {this.pagesCount > 1 && (
            <Container style={{ marginTop: '8px' }}>
              <Row className="justify-content-center">
                <Pagination aria-label="page navigation">
                  <PaginationItem disabled={currentPage <= 0}>
                    <PaginationLink
                      onClick={e => this.handleClick(e, currentPage - 1)}
                      previous
                    />
                  </PaginationItem>
                  {Array.from(Array(this.pagesCount).keys()).map((page, i) => {
                    return (
                      <PaginationItem active={i === currentPage} key={i}>
                        <PaginationLink
                          onClick={e => this.handleClick(e, i)}
                          // href={`#${categoryTitle.replace(/\s+/g, '')}`}
                        >
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    );
                  })}

                  <PaginationItem disabled={currentPage >= this.pagesCount - 1}>
                    <PaginationLink
                      onClick={e => this.handleClick(e, currentPage + 1)}
                      next
                    />
                  </PaginationItem>
                </Pagination>
              </Row>
            </Container>
          )}

          <br />
        </Collapse>
      </div>
    );
  }
}

export default FacebookPostsSection;
