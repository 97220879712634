import React, { Component } from 'react';
import { Card, CardImg, CardText, CardBody, CardSubtitle } from 'reactstrap';

import moment from 'moment';
import Lightbox from 'react-image-lightbox';
import styles from './FacebookPost.module.css';
import TNTTlogo from '../../img/tnttlogo-1.png';
import 'react-image-lightbox/style.css';

class FacebookPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lightboxOpen: false,
    };
  }

  render() {
    const { lightboxOpen } = this.state;
    const { post } = this.props;
    const {
      full_picture: picture,
      created_time: time,
      message,
      permalink_url: url,
    } = post;

    let picsrc;
    if (picture == null && message == null) {
      picsrc = TNTTlogo;
    } else {
      picsrc = picture;
    }

    return (
      <div className={styles.card}>
        <Card>
          {picsrc && (
            <CardImg
              top
              width="100%"
              src={picsrc}
              alt={time}
              className={
                message
                  ? `${styles.fbListImage}`
                  : `${styles.fbListImageNoMessage}`
              }
              onClick={() => this.setState({ lightboxOpen: true })}
            />
          )}

          <CardBody>
            <CardText
              className={picsrc ? styles.fbExcerpt : styles.fbExcerptNoPicture}
            >
              {message}
            </CardText>
            <CardSubtitle className={styles.bottomText}>
              <small className={styles.date}>
                {moment(time).format('MMMM D YYYY, h:mma')}
              </small>
              <a href={url} target="_blank" rel="noopener noreferrer">
                <small className={styles.viewMore}>View Post</small>
              </a>
            </CardSubtitle>
          </CardBody>
        </Card>
        {lightboxOpen && (
          <Lightbox
            mainSrc={picsrc}
            onCloseRequest={() => this.setState({ lightboxOpen: false })}
            imageCaption={message}
          />
        )}
      </div>
    );
  }
}

export default FacebookPost;
